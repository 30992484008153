import gql from 'graphql-tag';

export const LOGIN = gql`
	mutation Login($phone: String!, $password: String!) {
		authentication {
			login(phone: $phone, password: $password) {
				_id
				token
				tokenExpiration
				refreshToken
			}
		}
	}
`;

export const DELETE_CUSTOMER = gql`
	mutation DeleteCustomer($customer_id: ID!) {
		customer {
			delete(customer_id: $customer_id) {
				_id
			}
		}
	}
`;
