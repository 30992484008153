import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';

import router from '../router';
import store from '../store';

// HTTP connection to the API
const httpLink = createHttpLink({
	// You should use an absolute URL here
	uri: 'https://drcarapi.herokuapp.com/graphql',
});

const errorLink = onError(({ networkError }) => {
	// if (graphQLErrors) {
	// 	console.log('APOLLO LIN');
	// 	graphQLErrors.forEach(({ message, locations, path }) =>
	// 		console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
	// 	);
	// }

	if (networkError) {
		console.log('Network 400');
		store.commit('logout');
		router.push({ path: '/login' });
	}
});

const authLink = setContext((_, { headers }) => {
	const user = store.getters.user;

	if (user) {
		return {
			headers: {
				...headers,
				authorization: `Bearer ${user.token}`,
			},
		};
	}
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
	link: errorLink.concat(authLink.concat(httpLink)),
	cache,
});

export const apolloProvider = new VueApollo({
	defaultClient: apolloClient,
});

Vue.use(VueApollo);
