import gql from 'graphql-tag';

export const HelloWorld = gql`
	query HelloWorld {
		_empty
	}
`;

export const Test = gql`
	query PopularIssues {
		service {
			popularIssues {
				_id
				category
				subcategory
				custom_job
				title
			}
		}
	}
`;

export const AllCustomer = gql`
	query GetAllCustomers {
		customer {
			getAll {
				_id
				first_name
				last_name
				phone
				email
			}
		}
	}
`;
