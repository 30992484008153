<template>
	<div class="flex items-center justify-center min-h-screen">
		<div class="px-8 py-4 bg-gray-100 rounded shadow w-96">
			<section>
				<b-field label="Phone">
					<b-input v-model="phone" maxlength="10"></b-input>
				</b-field>
				<b-field label="Password">
					<b-input v-model="password" type="password" password-reveal></b-input>
				</b-field>
				<b-button type="is-primary" expanded class="mt-10" :loading="isLoading" @click="login">
					Login
				</b-button>
			</section>

			<!-- Error -->

			<b-message
				type="is-danger"
				aria-close-label="Close message"
				auto-close
				class="fixed top-0 right-0 z-40 w-64 m-4"
				v-if="error.is"
			>
				{{ error.message }}
			</b-message>
		</div>
	</div>
</template>

<script>
import { LOGIN } from '../gql/mutations';

export default {
	data() {
		return {
			phone: null,
			password: null,
			error: {
				is: false,
				message: null,
			},
			isLoading: false,
		};
	},

	methods: {
		async login() {
			this.clearError();
			this.isLoading = true;

			if (!(this.password && this.phone.length === 10)) {
				this.setError('Invalid Credentials');
			}

			const result = await this.loginMutation(this.phone, this.password);

			if (result && result.data.authentication.login.tokenExpiration === 4) {
				this.$store.commit('login', result.data.authentication.login);
				this.$router.push({ path: '/' });
			}
			this.isLoading = false;
		},

		async loginMutation(phone, password) {
			this.clearError();

			try {
				const result = await this.$apollo.mutate({
					mutation: LOGIN,
					variables: {
						phone,
						password,
					},
				});

				// console.log(result);
				return result;
			} catch (e) {
				this.setError(e);
			}
		},

		setError(err) {
			this.error.is = true;
			this.error.message = err;
		},

		clearError() {
			this.error.is = false;
			this.error.message = null;
		},

		onDone() {},
	},
};
</script>

<style></style>
