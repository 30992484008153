import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { apolloProvider } from './plugins/apollo';
import './plugins/buefy';
import './plugins/tailwind.css';

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	apolloProvider,
	render: h => h(App),
}).$mount('#app');
