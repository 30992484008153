<template>
	<b-navbar class="shadow" v-if="$route.path !== `/login`">
		<template #brand>
			<b-navbar-item tag="router-link" :to="{ path: '/' }">
				<strong>Dr🍁Car Internal Tooling</strong>
			</b-navbar-item>
		</template>

		<template #end>
			<b-navbar-item tag="div">
				<div class="buttons">
					<a class="button is-light" @click="logout">
						Log out
					</a>
				</div>
			</b-navbar-item>
		</template>
	</b-navbar>
</template>

<script>
export default {
	name: 'NavBar',

	data() {
		return {};
	},

	methods: {
		logout() {
			this.$store.commit('logout');
			this.$router.push({ path: '/login' });
		},
	},
};
</script>
