<template>
	<div class="m-16">
		<h1 class="ml-3 text-2xl font-semibold leading-none underline">Users Info</h1>
		<div class="w-full mt-4">
			<b-table
				v-if="allCustomers && allCustomers.length > 0"
				:data="allCustomers"
				:columns="columns"
				:debounce-search="500"
				:loading="$apollo.queries.allCustomers.loading"
				:selected.sync="selectedCustomer"
				class="w-full"
			>
			</b-table>

			<b-loading v-else :is-full-page="true" :active="true" :can-cancel="true"></b-loading>

			<div class="flex items-center justify-between mx-3 mt-4">
				<b-button
					label="Clear Selected"
					type="is-danger is-light"
					icon-left="close"
					:disabled="!selectedCustomer"
					@click="selectedCustomer = null"
				/>

				<delete-user-btn v-bind:selectedUser="selectedCustomer" />
			</div>
		</div>
	</div>
</template>

<script>
import DeleteUserBtn from '../components/DeleteUserBtn';
import { AllCustomer } from '../gql/queries';

export default {
	components: {
		DeleteUserBtn,
	},

	data() {
		return {
			allCustomers: null,
			selectedCustomer: null,
			columns: [
				{
					field: 'first_name',
					label: 'First Name',
				},
				{
					field: 'last_name',
					label: 'Last Name',
				},
				{
					field: 'phone',
					label: 'Phone',
					searchable: true,
					// centered: true,
					width: '200',
				},
				{
					field: 'email',
					label: 'Email',
					searchable: true,
				},
			],
		};
	},

	apollo: {
		allCustomers() {
			return { query: AllCustomer, update: data => data.customer.getAll };
		},
	},

	methods: {},
};
</script>

<style></style>
