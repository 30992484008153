<template>
	<div>
		<b-button
			label="Delete Selected"
			type="is-danger"
			icon-left="delete"
			:disabled="!selectedUser"
			@click="promptDelete"
		/>
		<b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
	</div>
</template>

<script>
import { DELETE_CUSTOMER } from '../gql/mutations';

export default {
	name: 'delete-user-btn',
	props: ['selectedUser'],
	data() {
		return {
			isLoading: false,
		};
	},
	methods: {
		async deleteMutation(customer_id) {
			let error = false;
			let value;

			try {
				const result = await this.$apollo.mutate({
					mutation: DELETE_CUSTOMER,
					variables: { customer_id },
				});

				value = result.data.customer.delete_id === customer_id;
			} catch (err) {
				error = true;
				value = err;
			}

			console.log({
				error,
				value,
			});

			return {
				error,
				value,
			};
		},

		promptDelete() {
			this.$buefy.dialog.prompt({
				title: 'Deleteing Customer',
				message: `What's your age?
          Are you sure you want to <b>delete</b> this <i>User</i> account? This action cannot be undone.
			    <br />
			    <br />
			    <b>Phone:</b> ${this.selectedUser.phone}
			    <br />
			    <b>Name:</b> ${this.selectedUser.first_name + ' ' + this.selectedUser.last_name}
			    <br />
			    <b>Email:</b> ${this.selectedUser.email}
        `,
				inputAttrs: {
					type: 'text',
					placeholder: 'Enter the users phone number to confirm',
				},
				confirmText: 'Delete Account',
				type: 'is-danger',
				trapFocus: true,
				closeOnConfirm: false,
				onConfirm: async (value, { close }) => {
					if (value !== this.selectedUser.phone) {
						this.$buefy.toast.open({
							message: 'Invalid Phone Number, Try again!',
							type: 'is-warning',
						});
					} else {
						this.isLoading = true;

						const result = await this.deleteMutation(this.selectedUser._id);
						!result.error
							? this.$buefy.toast.open({
									message: 'Success, you deleted the user! Try refreshing the page to see the result!',
									type: 'is-success',
							  })
							: this.$buefy.toast.open({
									message: `${result.value}`,
									type: 'is-danger',
							  });

						// await this.$apollo.queries.allCustomers.refetch();

						this.isLoading = false;
						close();
					}
				},
			});
		},
	},
};
</script>
