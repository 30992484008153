import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';

import Home from '../views/Home.vue';
import Login from '../views/Login.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '*',
		redirect: '/',
	},
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
	},
];

const router = new VueRouter({
	// A base needs to be applied to projects that are not at the root of a domain, refer the `publicPath` in vue.config.js -> https://router.vuejs.org/api/#base,
	mode: 'hash',
	base: '/',
	routes,
});

// Navigation Guard for BeforeEach
router.beforeEach((to, _from, next) => {
	if (to.name !== 'Login' && !store.getters.user) {
		next({ name: 'Login' });
	} else {
		next();
	}
});

export default router;
