import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const USER_ITEM = 'userItem';

export default new Vuex.Store({
	state: {
		user: null,
	},

	mutations: {
		login(state, payload) {
			localStorage.setItem(USER_ITEM, JSON.stringify(payload));
			state.user = payload;
		},

		logout(state) {
			localStorage.removeItem(USER_ITEM);
			state.user = null;
		},
	},

	getters: {
		user() {
			return JSON.parse(localStorage.getItem(USER_ITEM));
		},
	},

	actions: {},

	modules: {},
});
